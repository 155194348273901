import { Context } from '@nuxt/types'
import Products, { IProducts } from 'repositories/Products'
import Categories, { ICategories } from 'repositories/Categories/index'
import News, { INews } from 'repositories/News'
import Banners, { IBanners } from 'repositories/Banners'
import NotificationSubscribe, { INotificationSubscribe } from 'repositories/NotificationSubscribe'
import Footer, { IFooter } from 'repositories/Footer'
import Config, { IConfig } from 'repositories/Config'
import Cards, { ICards } from 'repositories/Cards'
import Basket, { IBasket } from 'repositories/Basket'
import Contacts, { IContacts } from 'repositories/Contacts'
import Menu, { IMenu } from 'repositories/Menu'
import Addresses, { IAddresses } from 'repositories/Addresses'
import Faq, { IFaq } from 'repositories/Faq'
import Content, { IContent } from 'repositories/Content'
import Profile, { IProfile } from 'repositories/Profile'
import ProductsFilters, { IProductsFilters } from 'repositories/Filters'
import Orders, { IOrders } from 'repositories/Order'
import Users, { IUsers } from 'repositories/Users'
import Geocoder, { IGeocoder } from 'repositories/Geocoder'
import Pharmacies, { IPharmacies } from 'repositories/Pharmacies'
import Disease, { IDisease } from 'repositories/Disease'
import Inns, { IInns } from 'repositories/Inns'
import Institutions, { IInstitutions } from 'repositories/Institutions'
import Producers, { IProducers } from 'repositories/Producers'
import Recipes, { IRecipes } from 'repositories/Recipes'
import Mindbox from 'repositories/Minbox'

export type repositoriesType = {
  products: IProducts
  categories: ICategories
  news: INews
  banners: IBanners
  notification: INotificationSubscribe
  footer: IFooter
  config: IConfig
  cards: ICards
  basket: IBasket
  contacts: IContacts
  menu: IMenu
  addresses: IAddresses
  faq: IFaq
  content: IContent
  profile: IProfile
  filters: IProductsFilters
  order: IOrders
  users: IUsers
  geocoder: IGeocoder
  pharmacies: IPharmacies
  inns: IInns
  institutions: IInstitutions
  disease: IDisease
  producers: IProducers
  recipes: IRecipes
  mindbox: any
}

const repositories = (ctx: Context): repositoriesType => ({
  products: new Products(ctx.$services.common.http),
  news: new News(ctx.$services.common.http),
  categories: new Categories(ctx.$services.common.http),
  banners: new Banners(ctx.$services.common.http),
  notification: new NotificationSubscribe(ctx.$services.common.http),
  footer: new Footer(ctx.$services.common.http),
  config: new Config(ctx.$services.common.http),
  cards: new Cards(ctx.$services.common.http),
  basket: new Basket(ctx.$services.common.http),
  contacts: new Contacts(ctx.$services.common.http),
  menu: new Menu(ctx.$services.common.http),
  addresses: new Addresses(ctx.$services.common.http),
  faq: new Faq(ctx.$services.common.http),
  content: new Content(ctx.$services.common.http),
  profile: new Profile(ctx.$services.common.http),
  filters: new ProductsFilters(ctx.$services.common.http),
  order: new Orders(ctx.$services.common.http),
  users: new Users(ctx.$services.common.http),
  geocoder: new Geocoder(ctx.$services.common.http),
  pharmacies: new Pharmacies(ctx.$services.common.http),
  inns: new Inns(ctx.$services.common.http),
  institutions: new Institutions(ctx.$services.common.http),
  producers: new Producers(ctx.$services.common.http),
  disease: new Disease(ctx.$services.common.http),
  recipes: new Recipes(ctx.$services.common.http),
  mindbox: new Mindbox(ctx.$services.common.http),
})

export default repositories
